//$fonts_path: "../../../fonts/" !default;
$fonts_path: "../fonts/" !default;

// Open Sans
@font-face {
	@include fontface("Open Sans", "#{$fonts_path}opensans/open-sans-v17-latin-regular", 400);
	font-display: auto;
}
@font-face {
	@include fontface("Open Sans", "#{$fonts_path}opensans/open-sans-v17-latin-italic", 400, italic);
	font-display: auto;
}
@font-face {
	@include fontface("Open Sans", "#{$fonts_path}opensans/open-sans-v17-latin-600", 600);
	font-display: auto;
}
@font-face {
	@include fontface("Open Sans", "#{$fonts_path}opensans/open-sans-v17-latin-600italic", 600, italic);
	font-display: auto;
}
@font-face {
	@include fontface("Open Sans", "#{$fonts_path}opensans/open-sans-v17-latin-700", 700);
	font-display: auto;
}
@font-face {
	@include fontface("Open Sans", "#{$fonts_path}opensans/open-sans-v17-latin-700italic", 700, italic);
	font-display: auto;
}

// TheSans
/*@font-face {
	@include fontface("TheSans", "#{$fonts_path}thesans/TheSansB3-LightItalic", 300, italic);
	font-display: auto;
}
@font-face {
	@include fontface("TheSans", "#{$fonts_path}thesans/TheSansB4-SemiLight", 400);
	font-display: auto;
}
@font-face {
	@include fontface("TheSans", "#{$fonts_path}thesans/TheSansB5-Plain", 500);
	font-display: auto;
}
@font-face {
	@include fontface("TheSans", "#{$fonts_path}thesans/TheSansB7-Bold", 700);
	font-display: auto;
}*/
