.footer {
	background: $footer-bg; //$white;
	bottom: 0;
	// border-top: 1px solid $gray-300;
	// box-shadow: 0 0 16px rgba($black, .2);
	// padding: rem-calc(10) 0;
	@include media("screen", ">=phone") {
		// height: rem-calc(73);
		position: absolute;
		width: 100%;
		// z-index: 99;
	}
	// position: fixed;
	// width: 100%;
	// z-index: 99;
	// @include media('screen', '>=tablet') {
	// 	padding: rem-calc(15) 0;
	// }
	// @include media('screen', '>=widescreen') {
	// 	padding: rem-calc(20) 0;
	// }

	&.magazine-footer {
		position: relative;
	}
}

.footer-container {
	@extend .container;
	@extend .d-flex;
	@extend .justify-content-between;
	// @extend .justify-content-center;
	@include media("screen", "<phone") {
		flex-direction: column-reverse;
		text-align: center;
	}
	@include media("screen", ">=tablet") {
		flex-direction: row;
		align-items: center;
	}

	a {
		color: $footer-link-color;
		font-weight: $footer-link-font-weight;
		&:hover {
			color: $footer-link-hover-color;
		}
	}
}