body {
	line-height: rem-calc(24);
	text-align: inherit;
	overflow-y: scroll;
	@include media("screen", ">=phone") {
		min-height: 100vh;
		// height: 100%;
		padding-bottom: 9rem;
		position: relative;
	}
	@include media("screen", ">=tablet") {
		padding-bottom: 12.5rem;
	}
}

hr {
	border-width: 1px;
}

#wrapper {
	position: relative;
	overflow: hidden;
}

.main {
	// margin-bottom: 81px;
	padding-bottom: 1.5rem;
	// @include media("screen", ">=phone") {
	// 	padding-bottom: rem-calc(140);
	// }
	// @include media("screen", ">=tablet") {
	// 	margin-bottom: 102px;
	// }

	// @include media('screen', '>=desktop') {
	// 	padding-top: rem-calc(12px);
	// }
}

// [class^='col'] {
// 	@include media('screen', '>=tablet', '<desktop') {
// 		padding: 0 25px;
// 	}
// }

.img-mask {
	position: relative;
	overflow: hidden;
	@include media("screen", "<phone") {
		text-align: center;
	}
	&:after {
		content: "";
		position: absolute;
		left: 0;
		top: 59%;
		width: 100%;
		height: 100%;
		//background-image: url(../images/mask-2.png);
	}

	&.item-1 {
		&:after {
			top: 10%;
		}
	}

	&.item-2 {
		&:after {
			top: 48%;
			//background-image: url(../images/mask-3.png);
		}
	}
}

.two-col-holder {
	@include media("screen", ">=desktop") {
		margin-bottom: rem-calc(50);
	}

	img {
		width: 100%;
		margin-bottom: 20px;
		@include media("screen", ">=tablet") {
			width: auto;
		}
	}
}
[class*="master-ip-"] {
	background-color: rgba($info, .1);
	border: .0625rem solid rgba($info, .5);
	border-radius: 1rem;
	color: darken($info, 5%);
	display: inline-flex;
	font-size: rem-calc(10);
	font-weight: 600;
	line-height: 1;
	margin-bottom: .5rem;
	padding: .25rem .5rem;
	text-transform: uppercase;
}