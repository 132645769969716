// COMMON BUTTON STYLES
$btn_icon-border-width: 0.125rem !default;
$btn_skew-right-width: rem-calc(12) !default;
$btn_skew-left: calc(-#{$btn_skew-right-width} / 2);
$btn-padding-right: $btn-padding-x * 3.125;

.btn {
	@include animate(all);
	&:hover {
		box-shadow: $box-shadow;
	}

	// DEFAULT BUTTON
	// &-default {
	// 	background: transparent;
	// 	border-color: $black;
	// 	color: $black;
	// }
}

.btn-round {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	height: $btn-round-size;
	width: $btn-round-size;
}

@each $color, $value in $theme-colors {
	.btn-outline-#{$color}:not(.btn-sm),
	.btn-#{$color}:not(.btn-sm) {
		// &[data-placement="left"],
		&[data-placement="right"] {
			position: relative;
		}

		// &[data-placement="left"],
		&[data-placement="right"] {
			*[class^="icon-"] {
				@include animate(background-color);
				display: inline-flex;
				align-items: center;
				// font-size: rem-calc(18);
				height: calc(100% + #{$btn_icon-border-width});
				padding-left: $btn-padding-x / 2;
				padding-right: $btn-padding-x;
				position: absolute;
				top: calc(-#{$btn_icon-border-width} / 2);
				&::after {
					content: "";
					background-color: inherit;
					display: inline-block;
					height: 100%;
					left: $btn_skew-left;
					position: absolute;
					transform: skewX(-15deg);
					width: $btn_skew-right-width;
				}
			}

			&:hover,
			&:focus {
				*[class^="icon-"] {
					background-color: darken($value, 0%);
				}
			}
		}

		&[data-placement="right"] {
			padding-right: $btn-padding-right;

			*[class^="icon-"] {
				@include border-right-radius($btn-border-radius);
				right: rem-calc(-1);
			}
		}
	}

	.btn-#{$color}:not(.btn-sm) {
		// &[data-placement="left"],
		&[data-placement="right"] {
			position: relative;

			*[class^="icon-"] {
				background-color: darken($value, 8%);
			}
		}
	}

	.btn-outline-#{$color}:not(.btn-sm) {
		// &[data-placement="left"],
		&[data-placement="right"] {
			*[class^="icon-"] {
				&::after {
					border-left: rem-calc(1) solid darken($value, 8%);
				}
			}
			&:hover,
			&:focus {
				*[class^="icon-"] {
					background-color: darken($value, 8%);
				}
			}
		}

		&:not([data-placement="right"]):not(.btn-round) {
			*[class^="icon-"] {
				margin-left: 0.5rem;
			}
		}
	}
}

.input-group-append {
	.btn:not([data-placement="right"]) {
		*[class^="icon-"] {
			margin-left: 0;
		}
	}
}

// ADDITIONAL BUTTON STYLES
.primaerLink {
	margin: rem-calc(20) 0;
}

.btn-search,
.dropdown-default-toggle {
	background: $white;
	border: 1px solid $gray-250;
	letter-spacing: 1.33px;
	position: relative;
	text-transform: uppercase;
	// Gradient ###
	// &::after {
	// 	@include animate(all 0.5s);
	// 	background-image: linear-gradient(to bottom, $white 0%, $gray-100 100%);
	// 	content: "";
	// 	position: absolute;
	// 	left: 0;
	// 	top: 0;
	// 	width: 100%;
	// 	height: 100%;
	// 	z-index: 0;
	// }
	&:hover,
	&:focus {
		color: $gray-900;
		outline: none;
		// Gradient ###
		// &::after {
		// 	background-image: linear-gradient(to top, $white 0%, $gray-100 100%);
		// }
	}

	& > * {
		display: inline-flex;
		position: relative;
		z-index: 5;
	}
}

.btn-search {
	*[class*="icon-"],
	*[class^="icon-"] {
		font-size: 1rem;
		margin-left: rem-calc(10);
	}
}

.btn-outline-category {
	background: $white;
	border-radius: rem-calc(3);
	border: 1px solid $gray-250;
	color: $gray-900;
	display: inline-flex;
	align-items: center;
	font-size: rem-calc(12);
	justify-content: center;
	letter-spacing: 0;
	padding: rem-calc(5) rem-calc(18) rem-calc(5) rem-calc(11);
	&:hover {
		cursor: default !important;
		color: $gray-900;
		&:before {
			background-image: linear-gradient(to top, $white 0%, $gray-100 100%);
			width: calc(100% - 1px);
			left: rem-calc(1);
		}
	}

	*[class^="icon-"] {
		font-size: 1rem;
		margin-right: rem-calc(5);
	}
}

// .btn-outline-default {
// 	background-color: transparent;
// 	&:hover {
// 		color: $gray-900;
// 	}
// }

// .lg-btn {
// 	color: $gray-900;
// 	font-weight: 600;
// 	line-height: 1.125;
// 	text-transform: uppercase;
// 	padding: rem-calc(15) rem-calc(18) rem-calc(16) rem-calc(17);
// 	font-size: rem-calc(10px);
// 	@include media("screen", ">=375px") {
// 		font-size: rem-calc(14);
// 	}
// }

button.close {
	@include animate(all);
	outline: 0;
}
