// FOOTER NAVIGATION
.footer-nav {
	@extend %listreset;
	font-size: $footer-nav-font-size;
	text-align: center;
	@include media("screen", "<phone") {
		margin-bottom: rem-calc(10);
	}
	// @include media('screen', '>=tablet') {
	// 	width: calc(100% - 120px);
	// }

	li {
		display: inline-block;
		margin-right: $footer-nav-item-margin-xs;
		@include media("screen", ">=phone") {
			margin-right: $footer-nav-item-margin-sm;
		}
		@include media("screen", ">=tablet") {
			display: inline-block;
			margin-right: 0;
		}
		&:not(:first-child) {
			&:before {
				content: $footer-nav-item-symbol;
				color: $footer-nav-item-color;
				margin-right: $footer-nav-item-symbol-margin;
			}
		}
		&:last-child {
			margin-right: 0;
		}
		&.active {
			@include media("screen", ">=tablet") {
				display: inline-block;
			}
			a {
				color: $gray-550;
			}
		}

		& + li {
			@include media("screen", ">=tablet") {
				display: inline-block;
				margin-left: $footer-nav-item-margin-md;
				margin-bottom: 0;
			}
		}
	}
}